<template>
  <span>
    <!--  
    {{ viewbox }}
    {{ colors }}
    -- {{ ratio }} -->

    <svg :width="w" height="10" preserveAspectRatio="true">
      <svg preserveAspectRatio="none" :viewbox="viewbox">
        <g>
          <g v-for="(value, index) in values" :key="index">
            <rect :x="calculateX(index)" y="0" :width="value * ratio" height="100" :fill="colors[index]" />
          </g>
        </g>
      </svg>
    </svg>
  </span>
</template>

<script>
/*

 :style="{ width: '400px', height: '10px' }"

 */

export default {
  props: {
    colors: {
      type: Array,
      required: true,
      // default: ["#1b4e6b", "#5c63a2", "#c068a8", "#ec7176", "#f4ab33"],
    },
    values: {
      type: Array,
      required: true,
    },
    w: {
      type: Number,
      required: false,
      default: 200,
    },
  },
  computed: {
    sum() {
      var sum = this.values.reduce((a, b) => a + b);
      return sum;
      //viewbox="0 0 66 40"
      // return this.values.reduce((a, b) => a + b);  // Sum of all values
    },
    ratio() {
      return this.w / this.sum;
      //viewbox="0 0 66 40"
      // return this.values.reduce((a, b) => a + b);  // Sum of all values
    },
    viewbox() {
      var sum = this.values.reduce((a, b) => a + b);

      return "0 0 " + sum + " 5";
      //viewbox="0 0 66 40"
      // return this.values.reduce((a, b) => a + b);  // Sum of all values
    },
  },
  methods: {
    calculateX(index) {
      let x = 0;
      for (let i = 0; i < index; i++) {
        x += this.values[i];
      }
      return (x / this.sum) * this.w;

      return x;
    },
  },
};
</script>

<style scoped>
svg {
  background: #ccc;
}
</style>
