const blueprintTypes = [
  {
    id: "convo",
    defaultName: "Messaging",
    icon: "fa-comment",
  },
  {
    id: "chat",
    defaultName: "Messaging",
    icon: "fa-comment-lines",
    title: "Chat",
    desc: "Define conversation logic",
    showInMenu: true,
  },
  {
    id: "answer",
    defaultName: "Search",
    icon: "fa-search",
  },
  {
    id: "custom",
    defaultName: "Custom Code",
    icon: "fa-code",
  },
  {
    id: "image",
    defaultName: "Image",
    icon: "fa-paint-brush",
    title: "Image generator",
    desc: "Create a custom image generator",
    showInMenu: true,
  },
  {
    id: "article",
    defaultName: "Article",
    icon: "fa-file-alt",
    title: "Article generator",
    desc: "Generate articles from custom guidelines",
    showInMenu: true,
  },
  {
    id: "idea",
    defaultName: "Idea",
    icon: "fa-lightbulb",
    title: "Ideas generator",
    desc: "Bulk generate content ideas for your bot",
    showInMenu: true,
  },
  {
    id: "calendar",
    defaultName: "Calendar",
    icon: "fa-calendar",
  },
  {
    id: "music",
    defaultName: "Music",
    icon: "fa-music",
  },
  {
    id: "api-call",
    defaultName: "API Call",
    icon: "fa-code",
  },
  {
    id: "api",
    defaultName: "External API",
    icon: "fa-code",
    title: "External API",
    desc: "Let your bot make API calls",
    showInMenu: true,
  },
  {
    id: "lora",
    defaultName: "Visual concept",
    icon: "fa-cogs",
    title: "Visual concept",
    desc: "Train image generator on custom images (Lora)",
    showInMenu: true,
  },
];

/*[
  { id: "convo", defaultName: "Messaging", icon: "fa-comment" },
  { id: "chat", defaultName: "Messaging", icon: "fa-comment-lines" },
  { id: "answer", defaultName: "Search", icon: "fa-search" },
  { id: "custom", defaultName: "Custom Code", icon: "fa-code" },
  { id: "image", defaultName: "Image", icon: "fa-paint-brush" },
  { id: "article", defaultName: "Article", icon: "fa-file-alt" },
  { id: "idea", defaultName: "Idea", icon: "fa-lightbulb" },
  { id: "calendar", defaultName: "Calendar", icon: "fa-calendar" },
  { id: "music", defaultName: "Music", icon: "fa-music" },
  { id: "api-call", defaultName: "API Call", icon: "fa-code" },
];
*/

const typeIcons = blueprintTypes.reduce((obj, type) => {
  obj[type.id] = type.icon;
  return obj;
}, {});

const defaultNames = blueprintTypes.reduce((obj, type) => {
  obj[type.id] = type.defaultName;
  return obj;
}, {});

const newBlueprintTypes = blueprintTypes.filter((i) => i.showInMenu);

export { blueprintTypes, typeIcons, defaultNames, newBlueprintTypes };
