//color stuff for buefy color.
export function convertToHex(color) {
  if (
    !color ||
    !color["$channels"] ||
    color["$channels"].length > 4 ||
    !Number.isFinite(parseFloat(color.alpha)) ||
    !Number.isFinite(parseFloat(color.lightness)) ||
    !Number.isFinite(parseFloat(color.saturation))
  ) {
    return null;
  }

  const channelList = ["red", "green", "blue"];

  const hexChannels = channelList
    .map((c) => {
      const channelVal = color["$channels"][channelList.indexOf(c)];
      if (channelVal < 0 || channelVal > 255 || !Number.isInteger(channelVal)) {
        return null; // Invalid value; return null to indicate failure
      }
      return convertToTwoDigitHex(channelVal); // else, return hex value of this channel (with leading zeroes if needed)
    })
    .filter((val) => val !== null); // remove any elements that were set to null above

  if (hexChannels.length < 3) {
    return null; // If not all required hex channels could be determined, return null as well.
  }

  return "#" + hexChannels.join(""); // concatenate hex channel strings and append a leading '#'
}

function convertToTwoDigitHex(num) {
  return num.toString(16).padStart(2, "0");
}
