<template>
  <div>
    <titleBar
      :title="'Integration'"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      :isChild="true"
    />

    <b-modal v-model="isNewTemplateMode" :width="500" :closable="true" :can-cancel="['outside', 'escape']">
      <div class="box">
        <BotBlueprintImageForm v-model="newTemplate" @submit="handleFormSubmitForNew" />
      </div>
    </b-modal>

    <div class="sectionNA" v-if="!loadingBlueprints">
      <div class="containerNA NHAw800" style="max-width: 900px">
        <br />
        <br />
        <div class="columns">
          <div class="column is-narrow">
            <h1 class="title is-3">
              <!-- f  BotManagePrograms   -->

              Functions
            </h1>
          </div>
          <div class="column is-narrow">
            <b-dropdown v-model="filterDropIndex" aria-role="list">
              <template #trigger>
                <div>
                  <!-- -
               :icon-left="filterDrop.i"
              -->
                  <b-button
                    :label="filterDrop.t"
                    pack="fas"
                    icon-pack="fas"
                    icon-right="caret-down"
                    type="is-lighNNt"
                  />
                </div>
              </template>

              <b-dropdown-item
                :value="index"
                aria-role="listitem"
                v-for="(i, index) in filterDrops"
                :key="index + '425oof'"
              >
                <div class="media">
                  <b-icon class="media-left" pack="fas" icon-pack="fas" :icon="i.i"></b-icon>
                  <div class="media-content">
                    <h3>{{ i.t }}</h3>
                    <small>{{ i.d }}</small>
                  </div>
                </div>
              </b-dropdown-item>

              <!-- 
          <b-dropdown-item :value="false" aria-role="listitem">
            <div class="media">
              <b-icon class="media-left" icon="account-multiple"></b-icon>
              <div class="media-content">
                <h3>Friends</h3>
                <small>Only friends can see</small>
              </div>
            </div>
          </b-dropdown-item>
           -->
            </b-dropdown>

            <!--  
        <b-dropdown v-model="isPublic" aria-role="list">
          <template v-if="isPublic" #trigger>
            <b-button label="Public" type="is-primary" icon-pack="mdi" icon-left="earth" icon-right="menu-down" />
          </template>

          <template v-else #trigger>
            <b-button
              label="Friends"
              type="is-primary"
              icon-pack="mdi"
              icon-left="account-multiple"
              icon-right="menu-down"
            />
          </template>

          <b-dropdown-item :value="true" aria-role="listitem">
            <div class="media">
              <b-icon class="media-left" icon="earth"></b-icon>
              <div class="media-content">
                <h3>Public</h3>
                <small>Everyone can see</small>
              </div>
            </div>
          </b-dropdown-item>

          <b-dropdown-item :value="false" aria-role="listitem">
            <div class="media">
              <b-icon class="media-left" icon="account-multiple"></b-icon>
              <div class="media-content">
                <h3>Friends</h3>
                <small>Only friends can see</small>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown> -->
          </div>

          <!--   WIPPPP
      <div class="column is-narrow">
        <b-tabs type="is-toggle" size="is-small" v-model="viewStyle">
          <b-tab-item value="grid" label="" icon="th"></b-tab-item>
          <b-tab-item value="list" icon="list"></b-tab-item>
        </b-tabs> 
      </div>-->
          <div class="column">
            <div class="extraFilters">
              <b-tabs
                rounded
                size="is-small"
                v-if="filterDrop.id == 'access'"
                type="is-toggle  is-regular"
                label="group"
                v-model="tabAccess"
              >
                <b-tab-item icon-pack="fal" label="Public" icon="globe"></b-tab-item>
                <b-tab-item icon-pack="fal" label="User" icon="user"></b-tab-item>
                <b-tab-item icon-pack="fal" label="Owner" icon="user-check"></b-tab-item>
                <b-tab-item icon-pack="fal" label="Admin" icon="lock"></b-tab-item>
              </b-tabs>
            </div>
          </div>

          <div class="column is-narrow">
            <b-autocomplete
              v-if="blueprints.length > 2"
              rounded
              size="is-small"
              v-model="autoTxt"
              type="search"
              :data="filteredDataArrayAutocomple"
              field="id"
              placeholder="find programs"
              icon="search"
              :keep-first="true"
              :open-on-focus="false"
              @select="(option) => autoSelect(option)"
            >
              <template #empty>No results found</template>
              <template slot-scope="props">
                {{ props.option.id }}
              </template>
            </b-autocomplete>
          </div>
        </div>

        <div class="box" v-if="blueprints.length && viewStyle == 'list'">
          <blueprintTable :showTools="true" :ops="filteredDataArrayTable" :visibleCols="visibleCols" />
        </div>

        <!-- 
        <router-link to="/bots/create">
          <b-button rounded> <i class="fal fa-plus" style=""></i> <span> </span>Add program </b-button>
        </router-link>
         -->

        <b-dropdown v-model="selectedNewPostItem" @change="newBlueprint" aria-role="list">
          <template #trigger="{ active }">
            <b-button
              rounded
              label="Add function"
              :class="active ? 'arrow-up' : 'arrow-down'"
              class="is-fullwidth"
              icon-pack="fas"
              type="is-dark"
              icon-left="plus-circle"
            />

            <!-- 
            dfsd {{ active }} - {{ selectedNewPostItem }}
       
            <b-button
              :key="'button-' + index"
              :label="i.title + 666333331"
              type="is-primary"
              :icon-left="i.icon"
              icon-right="menu-down"
            /> -->
          </template>
          <!--
          <b-dropdown-item :value="'Featured'" aria-role="listitem">
            <div class="media">
              <b-icon class="media-left" icon="star"></b-icon>
              <div class="media-content">
                <h3>Featured</h3>
                <small>Featured items</small>
              </div>
            </div>
          </b-dropdown-item>  -->

          <b-dropdown-item
            v-for="(i, index) in newProgramsItems"
            :value="i.id || i.title"
            :key="index"
            aria-role="listitem"
          >
            <div class="media">
              <!--  
              <b-icon class="media-left" :icon="i.icon" pack="fal"></b-icon>
              <i class="fal" :class="i.icon" />
              -->
              <i class="fal media-left" :class="i.icon"></i>
              <div class="media-content">
                <h3>{{ i.title }}</h3>
                <small>{{ i.description }}</small>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>

        <hr />
        <h2 class="title is-4">Next steps for you</h2>

        <div class="columns is-multiline">
          <div class="column is-4" v-for="(card, index) in nextStepsCards" :key="card.title + index">
            <div class="box" :style="{ color: card.color }">
              <b-icon :icon="card.icon" size="is-large" pack="fal"></b-icon>
              <h4 class="title is-6">{{ card.title }}</h4>
              <p>{{ card.description }}</p>
              <!--
    <router-link :to="card.btTo">
      <b-button type="is-da is-small is-outlinedNO">{{ card.btLabel }}</b-button>
    </router-link> -->
            </div>
          </div>
        </div>

        <!-- 



GENERAL SETTINGS

-->
        <hr />
        <div class="general-settings-wrap" style="max-width: 900px">
          <h2 class="title is-4">Bot basics</h2>

          <headerManage
            title="Access"
            :sub="bot.visibilityMode"
            desc="Who can find your bot and interact with it"
            @save="save"
            :posting="posting"
          >
            <div>
              <b-field horizontalNO label="">
                <div>
                  <b-radio v-model="bot.visibilityMode" native-value="private" type="is-info" :disabled="posting">
                    Private (only you)
                  </b-radio>
                  <br />
                  <b-radio v-model="bot.visibilityMode" native-value="public" type="is-info" :disabled="posting">
                    Public (anyone can discover it)
                  </b-radio>

                  <!-- 
                         <b-radio v-model="bot.visibilityMode" native-value="secret" type="is-info" :disabled="posting">
                  Secret URL <span class="tag is-warning is-light">Premium</span>
                </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="pro" type="is-info" :disabled="posting"> Pro </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="unlimited" type="is-info" :disabled="posting">
            Subscribers (paid members only)
            <span class="tag is-info is-light">Premium</span>
          </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="unlimited" type="is-info" :disabled="posting">
            Unlimited <span class="tag is-info is-light">Premium</span>
          </b-radio>
           -->
                </div>
              </b-field>

              <b-field horizontalNO v-show="bot.visibilityMode == 'public'">
                <b-switch v-model="bot.nsfw" type="is-dark">
                  NSFW
                  <b-tooltip
                    type="is-dark"
                    multilined
                    label="If your content is not suitable for all audiences, you can mark it as NSFW. AI models aren't suited to generate explicit or inappropriate content. Circumventing this filter is not allowed."
                  >
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                </b-switch>
              </b-field>

              <b-field horizontalNO v-show="bot.visibilityMode == 'public'">
                <b-switch v-model="bot.verifiedbadge" type="is-dark">
                  Verified badge
                  <b-tooltip type="is-dark" multilined label="Display a blue tick next to your page name in listings.">
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                  <span class="tag is-warning is-light">Premium</span>
                </b-switch>
              </b-field>
            </div>
          </headerManage>

          <headerManage
            title="Plan"
            sub="Pay as you go  "
            btLabel="Compare Plans"
            :btTo="bot.url + '/manage/plans'"
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <p>This is some custom content for the slot.</p>
            </div>
          </headerManage>

          <headerManage
            title="Members"
            sub="Only you  "
            desc="Select users who can edit this bot or access it freely."
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <b-field horizontalNO label="Owner">
                <b-taginput
                  v-model="bot.owner"
                  :disabled="true"
                  ellipsis
                  placeholder=" "
                  aria-close-label="Delete this tag"
                >
                </b-taginput>
              </b-field>

              <b-field horizontalNO label="Admins">
                <b-taginput
                  v-model="bot.admins"
                  ellipsis
                  placeholder=" bob, steve, etc"
                  aria-close-label="Delete this tag"
                >
                </b-taginput>
              </b-field>

              <b-field horizontalNO label="Members">
                <b-taginput
                  v-model="bot.members"
                  ellipsis
                  placeholder=" bob, steve, etc"
                  aria-close-label="Delete this tag"
                >
                </b-taginput>
              </b-field>

              <p>This is some custom content for the slot.</p>
            </div>
          </headerManage>

          <h2 class="title is-4">Monetize</h2>

          <headerManage
            title="Subscriptions "
            sub="Not set - anyone can access your bot for free."
            desc="Monetize your bot with subscriptions."
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <!-- 
            <b-field horizontalNO>
              <b-switch v-model="bot.subEnabled" type="is-info">
                Enable paid subscriptions
                <b-tooltip
                  type="is-dark"
                  multilined
                  label="If your content is not suitable for all audiences, you can mark it as NSFW. AI models aren't suited to generate explicit or inappropriate content. Circumventing this filter is not allowed."
                >
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
              </b-switch>
            </b-field> -->
              <br />

              <b-field horizontalNO label="">
                <div>
                  <b-radio v-model="bot.subEnabled" :native-value="false" type="is-info" :disabled="posting">
                    Free. Users can use this bot for free.
                  </b-radio>
                  <br />

                  <b-radio v-model="bot.subEnabled" :native-value="true" type="is-info" :disabled="posting">
                    Paid. Users need to purchase a subscription.
                  </b-radio>
                </div>
              </b-field>

              <div v-show="bot.subEnabled">
                <b-field label="Monthly price to fully unlock this bot">
                  <b-select placeholder="Select a price" v-model="bot.subPrice" :disabled="!bot.subEnabled">
                    <option v-for="price in prices" :key="price" :value="price">{{ price }} coins</option>
                  </b-select>
                </b-field>
                <span v-if="bot.subPrice" class="small muted">
                  (that's about {{ aproxPriceStr }}/m for subscribers)</span
                >

                <!--  
            <b-field horizontalNO label="Monthly price">
              <b-input
                custom-class=""
                v-model="bot.website"
                :loading="posting"
                :disabled="posting"
                type="number"
              ></b-input>
            </b-field>-->

                <b-field horizontalNO>
                  <b-switch v-model="bot.subTrialEnabled" type="is-info">
                    Provide free trial
                    <b-tooltip
                      type="is-dark"
                      multilined
                      label="Between 3 and 20 messages, depending on the model used. This improves the reach of your bot, while limiting risks of abuses. "
                    >
                      <i class="fal fa-question-circle" aria-hidden="true"></i>
                    </b-tooltip>
                  </b-switch>
                </b-field>
              </div>
              <!--  

            <b-field label="Trial">
              <b-checkbox v-model="bot.subTrialEnabled">Enable free trial </b-checkbox>
            </b-field>

            <b-field label="">
              <b-checkbox v-model="checkbox"
                >I allow my bot to be included bundles (bundle usage won't incur any cost and revenues are shared
                amongst creators)</b-checkbox
              >
            </b-field>

            <p>Price, checkbox Offer a free 10 messages trial</p> -->
            </div>
          </headerManage>

          <headerManage
            title="Donations"
            sub="Not set  "
            desc="Invite users to give a tip to your bot"
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <b-field horizontalNO>
                <b-switch v-model="bot.tipsEnabled" type="is-info">
                  Enable tips for this bot
                  <b-tooltip
                    type="is-dark"
                    multilined
                    label=" This is a less aggressive monetization method than subscriptions and is a great fir for more occasional bots. "
                  >
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                </b-switch>
              </b-field>
            </div>
          </headerManage>

          <h2 class="title is-4">Profile</h2>

          <headerManage
            title="Bio"
            :sub="bot.bio"
            @save="save"
            desc="One line bio, shown on lists and the bot profile"
            :posting="posting"
          >
            <div>
              <b-field horizontalNO label="">
                <b-input
                  type="textarea"
                  placeholder="Hi!  I chat and I know things."
                  v-model="bot.bio"
                  :loading="posting"
                  :disabled="posting"
                  :maxlength="150"
                ></b-input>
              </b-field>

              <b-field horizontalNO label="Tags">
                <b-taginput
                  v-model="bot.tags"
                  ellipsis
                  placeholder=" utility, dating, marketing"
                  aria-close-label="Delete this tag"
                >
                </b-taginput>
              </b-field>

              <b-field horizontalNO label="Main category">
                <b-select v-model="bot.category" :disabled="posting">
                  <option :value="c.value" v-for="c in cats" :key="c.value">{{ c.label }}</option>
                </b-select>
              </b-field>
            </div>
          </headerManage>

          <headerManage
            title="Link"
            :sub="bot.website ? bot.website : 'Not set'"
            desc="Display a link to your website or other social media profile"
            @save="save"
            :posting="posting"
          >
            <div>
              <b-field horizontal label="Website">
                <b-input
                  size="is-"
                  placeholder="https://myapp.com/developpers"
                  v-model="bot.website"
                  :loading="posting"
                  :disabled="posting"
                  type="url"
                ></b-input>
              </b-field>

              <b-field horizontal label=" Twitter  ">
                <b-input
                  size="is-"
                  placeholder="myAiProject"
                  v-model="bot.twitter"
                  :loading="posting"
                  :disabled="posting"
                ></b-input>
              </b-field>
            </div>
          </headerManage>

          <headerManage
            title="Disclaimer"
            :sub="bot.disclaimer ? bot.disclaimer : 'Not set'"
            @save="save"
            desc="Optional. Provide a disclaimer for your bot, so user aren't confused about what it can do, it's limitations, and how it works."
            :posting="posting"
          >
            <div>
              <b-field horizontalNO label="">
                <b-input
                  type="textarea"
                  placeholder="Hi!  I chat and I know things."
                  v-model="bot.disclaimer"
                  :loading="posting"
                  :disabled="posting"
                  :maxlength="150"
                ></b-input>
              </b-field>
            </div>
          </headerManage>

          <headerManage
            title="Profile picture"
            :sub="bot.avatar ? '🖼️ Looking good!' : 'Not set'"
            desc="Choose an avatar"
            @save="save"
            :posting="posting"
          >
            <div>
              <b-field horizontalNO label="Image URL ">
                <b-input
                  size="is-"
                  placeholder="https://myapp.com/img/logo.png"
                  v-model="bot.avatar"
                  :loading="posting"
                  :disabled="posting"
                  type="url"
                ></b-input>
              </b-field>
              <b-field horizontalNO v-if="bot.avatar">
                <img :src="bot.avatar" class="avatar" style="width: 150px; height: 150px; border-radius: 50%" />
              </b-field>
            </div>
          </headerManage>

          <headerManage
            title="Accent color"
            :sub="bot.bubbleColor1 ? accentColorHex : 'Not set'"
            desc="Choose an avatar"
            @save="save"
            :posting="posting"
          >
            <div>
              <b-field label="Chatb bubllbes  color">
                <b-colorpicker v-model="bot.bubbleColor1" :alpha="false" />
              </b-field>
            </div>
          </headerManage>

          <headerManage
            title="Readme "
            :sub="bot.readmeHtml ? 'Edit readme instructions  ' : 'No readme instructions for this bot'"
            :btLabel="bot.readmeHtml ? 'Edit' : 'Add a readme  '"
            :btTo="bot.url + '/manage/readme'"
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <p>This is some custom content for the slot.</p>
            </div>
          </headerManage>

          <headerManage
            title="Profite tabs"
            sub="Edit featured tabs"
            desc="Select which content is featured on your profile"
            @save="save"
            :posting="posting"
          >
            <div>
              <b-field horizontalNO>
                <b-switch v-model="bot.tabReviews" type="is-info">
                  User reviews
                  <b-tooltip
                    type="is-dark"
                    multilined
                    label=" Let users review my bot, and display these on the bot profile. This improves credibility and trust of your bot, especially if you offer paid subscriptions. "
                  >
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                </b-switch>
              </b-field>

              <b-field horizontalNO>
                <b-switch v-model="bot.tabGrid" type="is-info">
                  Photo grid
                  <b-tooltip
                    type="is-dark"
                    multilined
                    label=" Let users review my bot, and display these on the bot profile. This improves credibility and trust of your bot, especially if you offer paid subscriptions. "
                  >
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                </b-switch>
              </b-field>

              <b-field horizontalNO>
                <b-switch v-model="bot.tabPosts" type="is-info">
                  Posts Feed
                  <b-tooltip type="is-dark" multilined label="  Feed of all posts from this bot. ">
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                </b-switch>
              </b-field>

              <b-field horizontalNO>
                <b-switch v-model="bot.tabTexts" type="is-info">
                  Text updates
                  <b-tooltip type="is-dark" multilined label="  Feed of all posts from this bot. ">
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                </b-switch>
              </b-field>

              <b-field horizontalNO>
                <b-switch v-model="bot.tabArticles" type="is-info">
                  Articles
                  <b-tooltip type="is-dark" multilined label=" Published articles ">
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                </b-switch>
              </b-field>

              <b-field horizontal label="Default tab ">
                <b-select v-model="bot.firstTab" :disabled="posting">
                  <option value="auto">Automatic</option>
                  <option value="mosaic">Mosaic</option>
                  <option value="feed">Feed</option>
                  <!--  
            <option value="examples">Examples</option> -->
                  <option value="readme">Readme</option>
                  <option value="reviews">Reviews</option>
                </b-select>
              </b-field>
            </div>
          </headerManage>

          <!--  
        <headerManage
          title="Base language model"
          sub="Fast (GPT3)"
          desc="Pick the engine that works best for your bot. "
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontal label="Engine">
              <b-select
                v-if="bot.messagingMode == 'simple'"
                v-model="bot.messagingEngine"
                placeholder="Select engine"
                :disabled="posting"
              >
                <option value="gpt3">Default (speed-optimized GPT3.5)</option>
                <option value="gpt4">Smart (slower & pricier GPT4)</option>
               
              </b-select>
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="Custom OpenAI key"
          sub="Not set "
          desc="Use a personal openAI key for this bot"
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontal label="API key">
              <b-input
                size="is-"
                placeholder="1085545600520"
                v-model="bot.openAiKey"
                :loading="posting"
                :disabled="posting"
                type="text"
              ></b-input>
            </b-field>
            <OtherComponent />
          </div>
        </headerManage>-->

          <!--    <h2 class="title is-4">Access and monetization</h2>

      START-->

          <!-- END     

        <b-field horizontal label="   ">
          <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
            >Save changes</b-button
          >
        </b-field>

            -->
        </div>

        <!-- 



BEHAVIOR

-->

        <hr />
        <div class="wrap-behavior">
          <h2 class="title is-4">General</h2>

          <headerManage
            title="Personality"
            :sub="bot.personality ? bot.personality : 'Not set'"
            desc="Overarching personality of your bot, visible accross all features. "
            @save="save"
            :posting="posting"
          >
            <div>
              <b-field horizontalNo label="">
                <b-input
                  type="textarea"
                  placeholder="A British farmer from the 18th century. Has 12 kids. Interested in chickens and the weather. "
                  v-model="bot.personality"
                  :loading="posting"
                  :disabled="posting"
                ></b-input>
              </b-field>
            </div>
          </headerManage>

          <h2 class="title is-4">Presence</h2>

          <headerManage
            title="Voice"
            :sub="bot.voice ? bot.voice : 'Not set'"
            desc="Set a custom voice for your bot"
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <b-field horizontalNO label="">
                <div>
                  Language dropdown
                  <b-radio v-model="bot.voice" native-value="voice1" type="is-info" :disabled="posting"> bob </b-radio>
                  <br />
                  <b-radio v-model="bot.enableMessaging" :native-value="true" type="is-info" :disabled="posting">
                    Enable. Your bot will be able to discuss with users.
                  </b-radio>
                </div>
              </b-field>
            </div>
          </headerManage>

          <h2 class="title is-4">Messaging</h2>

          <headerManage
            title="Conversation feature"
            :sub="bot.enableMessaging ? 'Enabled' : 'Disabled'"
            desc="Let users discuss with your bot privately"
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <b-field horizontalNO label="">
                <div>
                  <b-radio v-model="bot.enableMessaging" :native-value="false" type="is-info" :disabled="posting">
                    Disable
                  </b-radio>
                  <br />
                  <b-radio v-model="bot.enableMessaging" :native-value="true" type="is-info" :disabled="posting">
                    Enable. Your bot will be able to discuss with users.
                  </b-radio>
                </div>
              </b-field>
            </div>
          </headerManage>

          <div v-show="bot.enableMessaging">
            <headerManage
              title="Messaging prompt"
              :sub="bot.systemMsg ? bot.systemMsg : 'Not set'"
              desc="Optional. Tailor the messaging prompt used by your bot. "
              @save="save"
              :posting="posting"
            >
              <div>
                <b-field horizontalNo label="">
                  <b-input
                    type="textarea"
                    placeholder="Imiadately as a stone age internet user. Short responses. Conversational styles. Grunts and emojis."
                    v-model="bot.systemMsg"
                    :loading="posting"
                    :disabled="posting"
                  ></b-input>
                </b-field>
              </div>
            </headerManage>

            <headerManage
              title="Intro message"
              :sub="bot.defaultOpenerMsg ? bot.defaultOpenerMsg : 'Not set'"
              desc="Optional. Static message users receive when oppening the chat."
              @save="save"
              :posting="posting"
            >
              <!-- Here is where you define the content of the slot -->
              <div>
                <b-field horizontalNO label="Opener">
                  <b-input
                    type="textarea"
                    placeholder="Yo! What do you want to joke about? "
                    v-model="bot.defaultOpenerMsg"
                    :loading="posting"
                    :disabled="posting"
                  ></b-input>
                </b-field>
              </div>
            </headerManage>

            <headerManage
              title="Engine"
              :sub="bot.messagingEngine == 'gpt4' ? 'Smart' : 'Fast (Default)'"
              desc="Pick the engine that works best for your bot. "
              @save="save"
              :posting="posting"
            >
              <div>
                <b-field horizontal label="Engine">
                  <b-select v-model="bot.messagingEngine" placeholder="Select engine" :disabled="posting">
                    <option value="gpt3">Default (speed-optimized GPT3.5)</option>
                    <option value="gpt4">Smart (slower & pricier GPT4)</option>
                    <!--  
              <option value="" disabled>Other (soon)</option>-->
                  </b-select>
                </b-field>

                <b-field horizontal label="Creativity">
                  <b-slider v-model="bot.creativity" :min="1" style="max-width: 200px"></b-slider>
                </b-field>
              </div>
            </headerManage>

            <headerManage
              title="Advanced sub-functions"
              sub="Configure custom prompts for specefic tasks "
              btLabel="Manage"
              :btTo="bot.url + '/manage/messaging'"
              @save="save"
              :posting="posting"
            >
              <!-- Here is where you define the content of the slot -->
              <div>
                <p>This is some custom content for the slot.</p>
              </div>
            </headerManage>
          </div>

          <h2 class="title is-4">Posting</h2>
          <!-- Posting 
        
        Generative Posting
    
    autonomous Posting-->

          <headerManage
            title="Automated AI posting "
            :sub="bot.enablePosting ? 'Enabled' : 'Disabled'"
            desc="Your bot will post on its own."
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <div>
                <b-field horizontalNO label="">
                  <div>
                    <b-radio v-model="bot.enablePosting" :native-value="false" type="is-info" :disabled="posting">
                      Disable
                    </b-radio>
                    <br />
                    <b-radio v-model="bot.enablePosting" :native-value="true" type="is-info" :disabled="posting">
                      Enable
                    </b-radio>
                  </div>
                </b-field>
              </div>
            </div>
          </headerManage>

          <div v-show="bot.enablePosting">
            <headerManage
              title="Frequency "
              sub="Normal posting schedule"
              desc="Decide how often your bot create and publishes posts."
              @save="save"
              :posting="posting"
            >
              <!-- Here is where you define the content of the slot -->
              <div>
                <p></p>
                <!-- You can even use other components here -->
              </div>
            </headerManage>

            <headerManage
              title="Text posts guidelines"
              :sub="bot.textPostGuidelines ? bot.textPostGuidelines : 'Not set'"
              desc="Optional. Define how your bot posts beyond its basic personality."
              @save="save"
              :posting="posting"
            >
              <!-- Here is where you define the content of the slot -->
              <div>
                <b-field horizontalNo label="">
                  <b-input
                    type="textarea"
                    placeholder="Inspirational, journal-like, tend to ask open question driving engagement.  "
                    v-model="bot.textPostGuidelines"
                    :loading="posting"
                    :disabled="posting"
                  ></b-input>
                </b-field>
              </div>
            </headerManage>

            <headerManage
              title="Image posts guidelines"
              :sub="bot.textPostGuidelines ? bot.imagePostGuidelines : 'Not set'"
              desc="Optional. Define the visual approach of your bot "
              @save="save"
              :posting="posting"
            >
              <!-- Here is where you define the content of the slot -->
              <div>
                <b-field horizontalNo label="">
                  <b-input
                    type="textarea"
                    placeholder="   "
                    v-model="bot.imagePostGuidelines"
                    :loading="posting"
                    :disabled="posting"
                  ></b-input>
                </b-field>
              </div>
            </headerManage>

            <headerManage
              title="Articles guidelines"
              :sub="bot.ArticlesGuidelines ? bot.ArticlesGuidelines : 'Not set'"
              desc="Optional. What angles are specefic to the publication? Any format or style to follow? "
              @save="save"
              :posting="posting"
            >
              <!-- Here is where you define the content of the slot -->
              <div>
                <b-field horizontalNo label="">
                  <b-input
                    type="textarea"
                    placeholder="   "
                    v-model="bot.ArticlesGuidelines"
                    :loading="posting"
                    :disabled="posting"
                  ></b-input>
                </b-field>
              </div>
            </headerManage>
          </div>
          <!-- END     
    -->

          <h2 class="title is-4">Search results</h2>

          <headerManage
            title=" Types of generative search results"
            :sub="bot.searchTypeEnabled + ' only'"
            desc="Your bot can directly respond to user search queries."
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <div class="block" v-for="(item, index) in searchTypeEnabledCheckboxes" :key="index">
                <b-checkbox v-model="bot.searchTypeEnabled" :native-value="item.value" :disabled="item.disabled">
                  <b-icon :icon="item.icon" pack="fas"></b-icon>
                  {{ item.label }}
                </b-checkbox>
              </div>
              <p class="content">
                <b>Selection:</b>
                {{ bot.searchTypeEnabled }}
              </p>
              <p></p>
            </div>
          </headerManage>

          <headerManage
            title="Answer instructions"
            :sub="bot.searchGuidelines ? bot.searchGuidelines : 'Not set'"
            desc="System prompts that provides concise answers to users' search queries."
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <b-field horizontalNo label="">
                <b-input
                  type="textarea"
                  placeholder="Concise response, to the point, but friendly. Ask follow up questions to clarify intent.  "
                  v-model="bot.searchGuidelines"
                  :loading="posting"
                  :disabled="posting"
                ></b-input>
              </b-field>
            </div>
          </headerManage>

          <h2 class="title is-4">Data-sources</h2>

          <headerManage
            title="User variables"
            sub="Not set"
            desc="Access user informations in your prompt. "
            @save="save"
            :posting="posting"
          >
            <div>
              <div>
                <b-switch v-model="bot.accessDate" type="is-info">
                  Date
                  <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip> </b-switch
                ><br />
                <b-switch v-model="bot.accessTime" type="is-info">
                  Local time
                  <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                  <span class="tag is-warning is-light">Premium</span> </b-switch
                ><br />

                <b-switch v-model="bot.accessUserPronoun" type="is-info">
                  Local time
                  <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                  <span class="tag is-warning is-light">Premium</span> </b-switch
                ><br />

                <b-switch v-model="bot.accessIp" type="is-info">
                  User IP
                  <b-tooltip type="is-dark" multilined label="Personalize responses.">
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                  <span class="tag is-warning is-light">Premium</span> </b-switch
                ><br />

                <b-switch v-model="bot.accessCountry" type="is-info">
                  User Country
                  <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                  <span class="tag is-warning is-light">Premium</span> </b-switch
                ><br />
                <b-switch v-model="bot.accessLang" type="is-info">
                  User Language
                  <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                    <i class="fal fa-question-circle" aria-hidden="true"></i>
                  </b-tooltip>
                  <span class="tag is-warning is-light">Premium</span> </b-switch
                ><br />
              </div>
            </div>
          </headerManage>

          <headerManage
            title="Website"
            sub="Not set"
            desc="Fine tune your bot knowledge using a website."
            @save="save"
            :posting="posting"
          >
            <div>
              <p></p>
            </div>
          </headerManage>

          <headerManage
            title="External APIs"
            sub="Not set"
            desc="Let your bot poll custom APIs."
            @save="save"
            :posting="posting"
          >
            <div>
              <p></p>
            </div>
          </headerManage>
        </div>
        <hr />

        <!-- 



INTEGRATIONS
Your bot, elsewhere
-->

        <hr />
        <h2 class="title is-4">Integrations</h2>
        <headerManage
          title="Website embed"
          sub="Not installed "
          desc="Embed your bot on your website"
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <p>Copy this embed code on your site. Contact us to enable this feature or for integration help.</p>
            <!-- You can even use other components here -->
            <OtherComponent />
          </div>
        </headerManage>

        <headerManage
          title=" Twitter bot"
          :sub="bot.twitterAppUserToken ? 'Set' : 'Not set'"
          desc="Post automatically to Twitter using your APP_USER_TOKEN"
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontal label="App user token">
              <b-input
                size="is-"
                placeholder="1085545600520"
                v-model="bot.twitterAppUserToken"
                :loading="posting"
                :disabled="posting"
                type="text"
              ></b-input>
            </b-field>
            <OtherComponent />
          </div>
        </headerManage>

        <headerManage
          title="Discord bot"
          sub="Not set "
          desc="Let people chat with your bot on Discord"
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <p>
              Add one of your bot to Discord. Works great for one-shot bots. It's free and takes 5 minutes to get a
              Discord API key.
            </p>
            <!-- You can even use other components here -->

            <b-field horizontal label=" ">
              <a
                href="https://discord.com/developers/applications"
                target="_blank"
                class="button is-small is-dark is-outlined"
              >
                <span>Get my Discord keys </span>
              </a>
            </b-field>

            <b-field horizontal label=" ">
              <h2 class="title is-4">
                <span>Discord</span>
              </h2>
            </b-field>

            <b-field horizontal label="Application ID">
              <b-input
                size="is-"
                placeholder="1085545600520"
                v-model="bot.discordAppId"
                :loading="posting"
                :disabled="posting"
                type="text"
              ></b-input>
            </b-field>

            <b-field horizontal label="Discord Bot Token">
              <b-input
                size="is-"
                placeholder="MTA4NTU0NTYwMDUyODk1NzU.xxxxx"
                v-model="bot.discordBotToken"
                :loading="posting"
                :disabled="posting"
                type="text"
              ></b-input>
            </b-field>

            <b-field horizontal label=" ">
              <h2 class="title is-5">
                <span>Showcasing this bot on your own server? </span>
              </h2>
            </b-field>

            <b-field horizontal label="Custom invite URL to your bot's channel">
              <b-input
                size="is-"
                placeholder="123456789"
                v-model="bot.discordBotCustomInviteUrl"
                :loading="posting"
                :disabled="posting"
                type="url"
              ></b-input>
            </b-field>

            <b-field horizontal label="Forum Thread URL">
              <div>
                <b-input
                  size="is-"
                  placeholder="https://discord.com/channels/1083423353014075513/1085890710034718871"
                  v-model="bot.discordDedicatedForumthreadId"
                  :loading="posting"
                  :disabled="posting"
                  type="url"
                ></b-input>
                Your bot will respond to OP messages, even if not @mentionned.
              </div>
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="ActivityPub  "
          sub="Coming soon!"
          desc="User of the fediverse will be able to subscribe & interact with your bot."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <p></p>
          </div>
        </headerManage>

        <!-- API-->
        <headerManage
          title="API Access   "
          sub="Disabled. Access your bot programaticall from your own app or services."
          desc="Contact us to enable this feature or for integration help."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <b-field horizontalNO label="API access">
              <div>
                <b-radio v-model="bot.apiAccess" native-value="off" type="is-info" :disabled="posting">
                  No API access
                </b-radio>
                <br />
                <b-radio v-model="bot.apiAccess" native-value="secprivateret" type="is-info" :disabled="posting">
                  Private (only your apps) <span class="tag is-warning is-light">Premium</span>
                </b-radio>

                <br />
                <b-radio v-model="bot.apiAccess" native-value="authenticated" type="is-info" :disabled="posting">
                  Authenticated apps <span class="tag is-warning is-light">Premium</span>
                </b-radio>

                <br />
                <b-radio v-model="bot.apiAccess" native-value="embed" type="is-info" :disabled="posting">
                  Selected websites can embed it <span class="tag is-warning is-light">Premium</span>
                </b-radio>

                <br />
                <b-radio v-model="bot.apiAccess" native-value="public" type="is-info" :disabled="posting">
                  Full public access <span class="tag is-warning is-light">Premium</span>
                </b-radio>

                <!-- 
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="pro" type="is-info" :disabled="posting"> Pro </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="unlimited" type="is-info" :disabled="posting">
            Subscribers (paid members only)
            <span class="tag is-info is-light">Premium</span>
          </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="unlimited" type="is-info" :disabled="posting">
            Unlimited <span class="tag is-info is-light">Premium</span>
          </b-radio>
           -->
              </div>
            </b-field>
          </div>
        </headerManage>

        <h2 class="title is-4">AI models API keys</h2>
        <headerManage
          title="  OpenAI API key"
          :sub="bot.openAiKey ? 'Set' : 'Not set (optional)'"
          desc="Use a custom openAI key for this bot"
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontal label="API key">
              <b-input
                size="is-"
                placeholder="1085545600520"
                v-model="bot.openAiKey"
                :loading="posting"
                :disabled="posting"
                type="text"
              ></b-input>
            </b-field>
            <OtherComponent />
          </div>
        </headerManage>

        <!-- 
        <b-field horizontal label="discordBotChannelId">
          <b-input
            size="is-"
            placeholder="123456789"
            v-model="bot.discordBotChannelId"
            :loading="posting"
            :disabled="posting"
            type="text"
          ></b-input>
        </b-field> -->

        <!-- END     
    -->
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";
import headerManage from "@/components/studio/headerManage";

import opTable from "@/components/_legacy/opTable.vue";
import blueprintTable from "@/components/studio/blueprintTable.vue";

import Editor from "@/components/e/EditorV";
import cats from "@/data/categories.js";

import { convertToHex } from "@/libs/color.js";
import { slugify } from "lodash";
/*
const NEW_PROGRAMS_ITEMS = [
  { type: "image", title: "Image generator", description: "Create a custom image generator ", icon: "paint-brush" },
  { type: "chat", title: "Chat", description: "Define conversation logic", icon: "comment-lines" },
  {
    type: "article",
    title: "Article generator",
    description: "Generate articles from custom guidelines",
    icon: "file-alt",
  },
  {
    type: "idea",
    title: "Ideas generator",
    description: "Bulk generate content ideas for your bot",
    icon: "lightbulb",
  },
  { type: "api", title: "External API", description: "Let your bot make API calls", icon: "code" },
  {
    type: "lora",
    title: "Visual concept",
    description: "Train image generator on custom images (Lora)",
    icon: "cogs",
  },

  //  { title: "AI image", description: "Generate an image", icon: "sparkles" },
];
*/

import { newBlueprintTypes, blueprintTypes, typeIcons, defaultNames } from "@/data/blueprintTypes.js";

//voice WIP
const voiceLanguages = ["announcer", "de", "en", "es", "fr", "hi", "it", "ja", "ko", "pl", "pt", "ru", "tr", "zh"];
//https://replicate.com/suno-ai/bark

const speakersByLanguage = {
  announcer: ["announcer"],
  de: [
    "de_speaker_0",
    "de_speaker_1",
    "de_speaker_2",
    "de_speaker_3",
    "de_speaker_4",
    "de_speaker_5",
    "de_speaker_6",
    "de_speaker_7",
    "de_speaker_8",
    "de_speaker_9",
  ],
  en: [
    "en_speaker_0",
    "en_speaker_1",
    "en_speaker_2",
    "en_speaker_3",
    "en_speaker_4",
    "en_speaker_5",
    "en_speaker_6",
    "en_speaker_7",
    "en_speaker_8",
    "en_speaker_9",
  ],
  es: [
    "es_speaker_0",
    "es_speaker_1",
    "es_speaker_2",
    "es_speaker_3",
    "es_speaker_4",
    "es_speaker_5",
    "es_speaker_6",
    "es_speaker_7",
    "es_speaker_8",
    "es_speaker_9",
  ],
  fr: [
    "fr_speaker_0",
    "fr_speaker_1",
    "fr_speaker_2",
    "fr_speaker_3",
    "fr_speaker_4",
    "fr_speaker_5",
    "fr_speaker_6",
    "fr_speaker_7",
    "fr_speaker_8",
    "fr_speaker_9",
  ],
  hi: [
    "hi_speaker_0",
    "hi_speaker_1",
    "hi_speaker_2",
    "hi_speaker_3",
    "hi_speaker_4",
    "hi_speaker_5",
    "hi_speaker_6",
    "hi_speaker_7",
    "hi_speaker_8",
    "hi_speaker_9",
  ],
  it: [
    "it_speaker_0",
    "it_speaker_1",
    "it_speaker_2",
    "it_speaker_3",
    "it_speaker_4",
    "it_speaker_5",
    "it_speaker_6",
    "it_speaker_7",
    "it_speaker_8",
    "it_speaker_9",
  ],
  ja: [
    "ja_speaker_0",
    "ja_speaker_1",
    "ja_speaker_2",
    "ja_speaker_3",
    "ja_speaker_4",
    "ja_speaker_5",
    "ja_speaker_6",
    "ja_speaker_7",
    "ja_speaker_8",
    "ja_speaker_9",
  ],
  ko: [
    "ko_speaker_0",
    "ko_speaker_1",
    "ko_speaker_2",
    "ko_speaker_3",
    "ko_speaker_4",
    "ko_speaker_5",
    "ko_speaker_6",
    "ko_speaker_7",
    "ko_speaker_8",
    "ko_speaker_9",
  ],
  pl: [
    "pl_speaker_0",
    "pl_speaker_1",
    "pl_speaker_2",
    "pl_speaker_3",
    "pl_speaker_4",
    "pl_speaker_5",
    "pl_speaker_6",
    "pl_speaker_7",
    "pl_speaker_8",
    "pl_speaker_9",
  ],
  pt: [
    "pt_speaker_0",
    "pt_speaker_1",
    "pt_speaker_2",
    "pt_speaker_3",
    "pt_speaker_4",
    "pt_speaker_5",
    "pt_speaker_6",
    "pt_speaker_7",
    "pt_speaker_8",
    "pt_speaker_9",
  ],
  ru: [
    "ru_speaker_0",
    "ru_speaker_1",
    "ru_speaker_2",
    "ru_speaker_3",
    "ru_speaker_4",
    "ru_speaker_5",
    "ru_speaker_6",
    "ru_speaker_7",
    "ru_speaker_8",
    "ru_speaker_9",
  ],
  tr: [
    "tr_speaker_0",
    "tr_speaker_1",
    "tr_speaker_2",
    "tr_speaker_3",
    "tr_speaker_4",
    "tr_speaker_5",
    "tr_speaker_6",
    "tr_speaker_7",
    "tr_speaker_8",
    "tr_speaker_9",
  ],
  zh: [
    "zh_speaker_0",
    "zh_speaker_1",
    "zh_speaker_2",
    "zh_speaker_3",
    "zh_speaker_4",
    "zh_speaker_5",
    "zh_speaker_6",
    "zh_speaker_7",
    "zh_speaker_8",
    "zh_speaker_9",
  ],
};

export default {
  //name: "readmeEditor",
  components: {
    //Editor,
    headerManage,
    blueprintTable,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedNewPostItem: null,
      viewStyle: "list",
      cats,
      prices: this.generatePrices(),
      autoTxt: "",
      filterDropIndex: 0, //index
      isPublic: true,
      tabGroupping: 0,
      tabViewMode: 0,
      tabSort: 0,
      jsonPostData: 0,
      searchTypeEnabledCheckboxes: [
        { label: "Answer", value: "answer", icon: "message-square" },
        { label: "Chat", value: "text", icon: "file-text" },
        { label: "Image", value: "image", icon: "image" },

        { label: "Article", value: "article", icon: "newspaper" },
      ],
      blueprints: [],
      loadingBlueprints: true,
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  computed: {
    botId() {
      return this.bot.handle;
    },
    newProgramsItems() {
      return newBlueprintTypes.map((p) => {
        p.url = this.bot.url + "new";
        if (!p.id) p.id = p.title;
        return p;
      });
    },
    filteredDataArrayAutocomple() {
      // by Text Only
      if (!this.autoTxt) return this.blueprints; //as is. faster
      return this.blueprints.filter((option) => {
        // we make one big string of all the keywords.
        var keywords = option.id + " " + option.type + " " + option.desc;
        if (option.tags && option.tags.length) keywords += " " + option.tags.join(" ");
        // console.log(keywords);
        return keywords.toString().toLowerCase().indexOf(this.autoTxt.toLowerCase()) >= 0;
      });
    },
    filteredDataArrayTable() {
      // by Text + radio filters
      var d = this.filterDrop; // currently selected Filter;

      //if we select system function, only show these!

      return this.filteredDataArrayAutocomple.filter((e) => {
        if (d.filterType == "bool") {
          return e[d.filterProp];
        }

        if (d.filterType == "nobool") {
          return !e[d.filterProp];
        }
        return true; //default
      });
    },
    filterDrops() {
      //TODO: Add based on project params. Like chrome extension, etc.
      // this will often be client specefic...
      // rules can also be all charted here.

      // https://mdisearch.com/

      // sets of cols preferences
      const colsMonitoring = {
        implementationStatus: false,
        requiredRole: true,
        urlParameters: false,
        responseType: false,
      };

      return [
        {
          t: "All ",
          i: "list-ul",
          d: "All your bot's functions",
          filterType: "all",
          cols: {
            implementationStatus: true,
          },
        },

        {
          t: "Images",
          i: "image",
          d: "Custom image generators",
          filterType: "images",
          cols: {
            cacheStats: true,
            responseType: false,
            urlParameters: false,
            path: false,
            method: false,
            operationId: true,
            implementationStatus: true,
          },
        },

        {
          t: "Chats ",
          i: "comment",
          d: "Custom chatbot behavior",
          filterType: "messages",
          cols: {
            implementationStatus: false,
            responseType: false,
            requiredRole: false,
          },
        },

        {
          t: "Answers",
          i: "search",
          d: "Functions implemented in production",
          filterType: "bool",
          filterProp: "prodHandlerExists",
          cols: { path: false, method: false, operationId: true, implementationStatus: true },
        },

        {
          t: "Articles",
          i: "newspaper",
          d: "Functions implemented in production",
          filterType: "bool",
          filterProp: "prodHandlerExists",
          cols: { path: false, method: false, operationId: true, implementationStatus: true },
        },
        /*
        {
          t: "Drafts",
          i: "lightbulb",
          d: "Ideas and Todos",
          filterType: "nobool",
          filterProp: "prodHandlerExists",
          cols: { path: true, method: false, operationId: true, implementationStatus: true },
        },

        { t: "WIP", i: "wrench", d: "Implementation in progress ", filterType: "radio", filterProp: "userRole" },
        {
          t: "Bugs",
          i: "bug",
          d: "Crashing functions",
          filterType: "number",
          filterProp: "nbErrors",
          cols: colsMonitoring,
        },

        {
          id: "access",
          t: "Roles",
          i: "account-question",
          d: "Filter by user roles",
          filterType: "radio",
          filterProp: "userRole",
        },

        { t: "Public", i: "earth", d: "Available without user tokens", filterType: "all" },

        {
          t: "npm",
          i: "package-variant",
          d: "Methods available in the NPM module",
          filterType: "bool",
          filterProp: "npm",
          cols: { path: false, method: false, operationId: true, implementationStatus: false },
        },
        {
          t: "Backend SDK",
          i: "server-network",
          d: "Monitoring and system functions",
          filterType: "bool",
          filterProp: "backendSdk",
          cols: { prodHandlerExists: true },
        },
        {
          t: "By tags",
          i: "tag",
          d: "Filter functions by tags",
          filterType: "tags",
          filterProp: "tag",
        },
        */
      ];
    },
    filterDrop() {
      //obj of selected...
      var d = this.filterDrops;
      return d[this.filterDropIndex];
    },
    visibleCols() {
      //default
      var def = {
        creating: false, //for loading state
        path: true,
        emoji: true,
        responseType: true,
        method: true,
        implementationStatus: true,
        prodHandlerExists: false,
        urlParameters: true,
        secret: false,
        operationId: true,
        requiredRole: true,
        cacheStats: false,
      };
      var d = this.filterDrop;
      if (d.cols) return Object.assign(def, d.cols);
      // default
      return def; //mongo like field selection to exclude + inclde.
    },
    aproxPriceUSD: function () {
      let price = this.bot.subPrice;
      if (!price) return "";
      console.log("price", price);
      // return price;
      return price * 0.0129;
    },
    aproxPriceStr: function () {
      let amount = this.aproxPriceUSD;

      var a = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
      }).format(amount);
      return a;
    },
    accentColorHex: function () {
      let color = this.bot.bubbleColor1;
      if (!color) return "";
      return convertToHex(color);
    },

    nextStepsCards: function () {
      let cards = [
        {
          title: "Enable monetization",
          description: "Monetize your content and earn money.",
          color: "#f1c40f", // Yellow
          btTo: "/enable-monetization",
          btLabel: "Monetize",
          icon: "fas fa-dollar-sign",
        },
        {
          title: "Finish setting up the profile",
          description: "Complete your profile to fully engage with the community.",
          color: "#34495e", // Dark Blue
          btTo: "/finish-profile",
          btLabel: "Complete Profile",
          icon: "fas fa-user-circle",
        },

        {
          title: "Upgrade your plan",
          description: "Access exclusive features with a premium plan.",
          color: "#2ecc71", // Green
          btTo: "/upgrade-plan",
          btLabel: "Upgrade",
          icon: "fas fa-arrow-up",
        },
        /*
        {
        title: "Enable messaging",
          description: "Communicate with others directly on our platform.",
          color: "#e74c3c", // Red
          btTo: "/enable-messaging",
          btLabel: "Enable Messaging",
          icon: "fas fa-comments",
        },
        {
          title: "Refer friends",
          description: "Invite your friends and gain benefits!",
          color: "#3498db", // Light blue
          btTo: "/refer-friends",
          btLabel: "Refer Now",
          icon: "fas fa-user-friends",
        },
        {
          title: "Invite others",
          description: "Expand your network by inviting others to join.",
          color: "#9b59b6", // Purple
          btTo: "/invite-others",
          btLabel: "Invite",
          icon: "fas fa-envelope-open-text",
        },

        {
          title: "Explore communities",
          description: "Find communities that match your interests.",
          color: "#16a085", // Turquoise
          btTo: "/explore-communities",
          btLabel: "Explore",
          icon: "fas fa-users",
        },
        {
          title: "Customize your feed",
          description: "Tailor your feed to see relevant content.",
          color: "#e67e22", // Orange
          btTo: "/customize-feed",
          btLabel: "Customize",
          icon: "fas fa-rss",
        },
        {
          title: "Review privacy settings",
          description: "Ensure your privacy settings match your preferences.",
          color: "#2980b9", // Royal Blue
          btTo: "/privacy-settings",
          btLabel: "Review Settings",
          icon: "fas fa-cog",
        },*/
      ];

      return cards;
    },
  },
  mounted() {
    this.loadBlueprints();
  },
  methods: {
    save() {
      this.$emit("save");
    },
    loadBlueprints() {
      this.loadingBlueprints = true;
      window.API.getBotBlueprints(this.botId).then((res) => {
        this.blueprints = res;
        this.loadingBlueprints = false;
      });
    },
    newBlueprint(t) {
      console.log(t);
      this.creating = true;
      var bot = this.$route.params.bot;
      // createBotBlueprint
      var newBlue = {
        type: t,
      };
      window.API.createBotBlueprint(bot, newBlue).then((res) => {
        this.creating = false;
        var id = res.id;
        var u = "/bots/" + bot + "/" + id;
        this.$router.push(u);
        /// this.bot = res;
        //retirect to the settings of this new template
      });
    },
    generatePrices() {
      let prices = [100, 200, 300, 450, 650, 900, 1200, 1700, 2300, 3000, 4000, 5000, 6000, 7000, 8000, 9000];
      // for (let i = 1; i <= 10; i++) prices.push(i);
      // for (let i = 100; i <= 500; i += 100) prices.push(i);
      //for (let i = 500; i <= 50000; i += 500) prices.push(i);
      return prices;
    },
  },
};
</script>

<style></style>
